import React from "react";
import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col, UncontrolledCarousel } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSrc } from "gatsby-plugin-image";
import {
  faHotel,
  faCommentAlt,
  faMapMarkedAlt,
  faSpa
} from "@fortawesome/free-solid-svg-icons";
import { isIE } from "react-device-detect";

import "./Venue.scss";

export default function Venue({ hideIcons, className }) {
  return (
    <StaticQuery
      query={graphql`
        query VenueImages {
          allFile(
            filter: {relativeDirectory: {eq: "venue-carousel"}}
            sort: {order: ASC, fields: name}
          ) {
            nodes {
              relativeDirectory
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      `}
      render={data => {
        const items = [];
        data.allFile.nodes.map((image, index) => {
          const imageObject = getSrc(image.childImageSharp);
          items.push({
            src: imageObject,
            key: index,
            caption: ``
          });
        })

        return (
          <Container
            style={{
              marginBottom: "2rem",
              marginTop: "2rem"
            }}
            className={className}
          >
            <Row>
              <Col
                lg={10}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "2rem"
                }}
              >
                {!isIE && <UncontrolledCarousel items={items} />}
              </Col>
            </Row>
            {!hideIcons && (
              <>
                <Row className="venue-icons">
                  <Col lg={6}>
                    <Row>
                      <Col lg="auto">
                        <FontAwesomeIcon
                          className="venue-icon"
                          icon={faHotel}
                          fixedWidth
                          size="3x"
                          color="#FFFFFF"
                          style={{
                            background: "#0085ad"
                          }}
                        />
                      </Col>
                      <Col>
                        <p>
                          --
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row>
                      <Col lg="auto">
                        <FontAwesomeIcon
                          className="venue-icon"
                          icon={faCommentAlt}
                          fixedWidth
                          size="3x"
                          color="#FFFFFF"
                          style={{
                            background: "#7ac24f"
                          }}
                        />
                      </Col>
                      <Col>
                        <p>
                          --
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="venue-icons">
                  <Col lg={6}>
                    <Row>
                      <Col lg="auto">
                        <FontAwesomeIcon
                          className="venue-icon"
                          icon={faMapMarkedAlt}
                          fixedWidth
                          size="3x"
                          color="#FFFFFF"
                          style={{
                            background: "#615E9B"
                          }}
                        />
                      </Col>
                      <Col>
                        <p>
                          --
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row>
                      <Col lg="auto">
                        <FontAwesomeIcon
                          className="venue-icon"
                          icon={faSpa}
                          fixedWidth
                          size="3x"
                          color="#FFFFFF"
                          style={{
                            background: "#e57200"
                          }}
                        />
                      </Col>
                      <Col>
                        <p>
                          --
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm={12}
                    style={{
                      textAlign: "center",
                      marginTop: "2rem"
                    }}
                  >
                    <a className="primary-cta" href="/attend/venue/">
                      Learn More
                    </a>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        )
      }}
    />
  )
}